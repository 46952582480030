if ($('#contact').length) {
	// Google Recaptcha v3
	grecaptcha.ready(function () {
		$('form.mod-recaptcha').each(function () {
			var object = $(this);
			// TODO: replace key with project one - this one is a Codename framework key
			grecaptcha.execute('6Lf3-vAdAAAAAJn7sQUVK_cKp0UbtMRAkYeTucqS', { action: 'homepage' }).then(function (token) {
				$('<input>').attr({
					type: 'hidden',
					name: 'g-recaptcha-response',
					value: token
				}).appendTo(object)
			});
		});
	});
}

/**
 * Footer terms of business dropdown
 */

$('.list-footer-links li.mod-dropdown span').click(function (e) {
	e.preventDefault();

	$(this).next('.list-terms').toggleClass('mod-active');

});

/**
 * Homepage hero scroll effect on text
 */

$(window).on('scroll load scroll', function () {
	if ($(window).width() > 1024) {
		const hero = $('body#home .section-hero .sub-content'),
			heroAlt = $('body:not(#home) .section-hero .sub-content');

		// Parallax effect on homepage hero
		hero.css({
			"top": Math.max(19 + 0.04 * window.scrollY, 0) + "%",
			"opacity": 1 - $(window).scrollTop() / 750
		});

		heroAlt.css({
			"top": Math.max(50 - 0.06 * window.scrollY, 0) + "%",
			"opacity": 1 - $(window).scrollTop() / 650
		});
	}
});

// Mobile Nav
$('.mob-hamburger').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$('body').toggleClass('no-scroll');
	$('#nav').toggleClass('mod-active');
	$('.logo').fadeToggle();
	$('.list-nav li a.mod-dropdown').removeClass('mod-active');
	$('ul.sub-dropdown').removeClass('mod-active');
});

// Prevent dropdown link being clicked on
$('.list-nav li a.mod-dropdown').click(function (ev) {
	ev.preventDefault();

	$(this).toggleClass('mod-active');
	$(this).next('ul.sub-dropdown').toggleClass('mod-active');
});

// Show/hide cookie policy
$("body").on("click", ".section-cookies .btn-accept", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
	}).done(function (response) {
		$(".section-cookies").slideToggle();
	});
});

$(".section-cookies .btn-decline").on("click", (ev) => {
	ev.preventDefault();

	$(".section-cookies").slideToggle();
});

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact form .form-element').removeClass('mod-filled');
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .wrap-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .wrap-thanks').hide();
			}, 5000);

		}
		else {
			$('#contact form .sub-errors').fadeIn().html(response);
		}
	});
});

// Add class to inputs when clicked on add effect

if ($('form').length) {
	$('form input, form textarea').focus(function () {
		$(this).parent().addClass('mod-filled');

	}).blur(function () {
		if ($(this).val()) {
			$(this).parent().addClass('mod-filled');
		} else {
			$(this).parent().removeClass('mod-filled');
		}
	});
}


if ($('#capabilities').length) {
	$('.list-sectors li > h3').click(function (ev) {
		ev.preventDefault();

		$(this).parent().toggleClass('mod-active');
	});
}

// Google Maps

// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [
			['Head Office - Milton Keynes', lat, long, 1],
			['London Office', 51.50429593609211, -0.01943647301446826, 1],
		];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "all", "elementType": "geometry.fill", "stylers": [{ "weight": "2.00" }] }, { "featureType": "all", "elementType": "geometry.stroke", "stylers": [{ "color": "#9c9c9c" }] }, { "featureType": "all", "elementType": "labels.text", "stylers": [{ "visibility": "on" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "landscape", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "landscape.man_made", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] }, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "road", "elementType": "labels.text.fill", "stylers": [{ "color": "#7b7b7b" }] }, { "featureType": "road", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#46bcec" }, { "visibility": "on" }] }, { "featureType": "water", "elementType": "geometry.fill", "stylers": [{ "color": "#c8d7d4" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#070707" }] }, { "featureType": "water", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }],
			{ name: 'Styled Map' }
		);

		melonMap.panBy(130, 130);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 52.0469307, -0.7995591, 9, true);
	}
}

document.querySelectorAll('.scroll-to').forEach((element) => {
	element.addEventListener('click', (e) => {
		e.preventDefault();

		var link = element.hash;
		var targetScroll = $(link).offset().top - 90;

		$('html, body').animate({ scrollTop: targetScroll }, 700, 'swing');
	});
});

$('ul.list-testimonials').slick({
	arrows: true,
	autoplay: true,
	autoplaySpeed: 5000,
	adaptiveHeight: true,
	dots: false,
	fade: false,
	infinite: true,
	nextArrow: $('.sub-testimonials-nav .sub-next'),
	prevArrow: $('.sub-testimonials-nav .sub-prev'),
	slidesToScroll: 1,
	slidesToShow: 1,
});

$('#home .section-hero .slides').slick({
	arrows: false,
	autoplay: true,
	autoplaySpeed: 5000,
	adaptiveHeight: false,
	dots: true,
	fade: true,
	infinite: true,
	slidesToScroll: 1,
	slidesToShow: 1,
	asNavFor: '#home .section-hero .images',
	appendDots: $('.slider-dots')
});

$('#home .section-hero .images').slick({
	arrows: false,
	autoplay: true,
	autoplaySpeed: 5000,
	adaptiveHeight: false,
	dots: true,
	fade: true,
	infinite: true,
	slidesToScroll: 1,
	slidesToShow: 1,
	asNavFor: '#home .section-hero .slides'
});

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = screen.width / 2 - width / 2;
	const top = screen.height / 2 - height / 2;

	// opens a window with the given url & set position from above
	window.open(
		url,
		"",
		"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" +
		width +
		",height=" +
		height +
		",top=" +
		top +
		",left=" +
		left
	);
}

// Home Page Modal
document.addEventListener('DOMContentLoaded', () => {
	const body = document.querySelector('body');
	const modal = document.querySelector('.modal'); // Cache modal element
	const modalBtn = document.querySelectorAll('.js-modal-btn'); // Buttons to open modal
	const modalClose = document.querySelector('.close-read-more-content-modal-btn'); // Close button

	if (modalBtn) {
		// Open modal
		modalBtn.forEach((button) => {
			button.addEventListener('click', (event) => {
				event.preventDefault();

				// Show the modal
				modal.classList.remove('mod-read-more-content-modal-hidden');
				modal.setAttribute('aria-hidden', 'false'); // Update accessibility attribute
				body.style.overflow = 'hidden'; // Disable background scroll
			});
		});

		// Close modal when clicking the close button
		if (modalClose) {
			modalClose.addEventListener('click', (event) => {
				event.preventDefault();

				// Hide the modal
				modal.classList.add('mod-read-more-content-modal-hidden');
				modal.setAttribute('aria-hidden', 'true'); // Update accessibility attribute
				body.style.overflow = 'visible'; // Re-enable background scroll
			});
		}

		// Close modal when clicking outside the modal content
		window.addEventListener('click', (event) => {
			if (event.target === modal) {
				modal.classList.add('mod-read-more-content-modal-hidden');
				modal.setAttribute('aria-hidden', 'true'); // Update accessibility attribute
				body.style.overflow = 'visible'; // Re-enable background scroll
			}
		});

		// Close modal with the Escape key
		window.addEventListener('keydown', (event) => {
			if (event.key === 'Escape' && !modal.classList.contains('mod-read-more-content-modal-hidden')) {
				modal.classList.add('mod-read-more-content-modal-hidden');
				modal.setAttribute('aria-hidden', 'true'); // Update accessibility attribute
				body.style.overflow = 'visible'; // Re-enable background scroll
			}
		});
	} else {
		console.error('Modal or trigger buttons not found on the page.');
	}
});